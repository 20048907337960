import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DarkModeSwitch from './DarkModeSwitch';
import { ThemeContext } from '../context/ThemeContext';
import { FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';
import { useClickContext } from '../context/ClickContext';

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: 'white',
  color: 'black',
  height: '60px',
  position: 'sticky' as 'sticky',
  top: '0',
  zIndex: 1,
};

const linkStyle = {
  color: 'blue',
  textDecoration: 'underline',
  marginLeft: 10
};

const activeLinkStyle = {
  color: 'inherit',
  textDecoration: 'none',
};

const countStyle = {
  marginLeft: 10,
  fontSize: '0.8em',
  color: '#666'
};

const Header = () => {
  const themeContext = useContext(ThemeContext);
  const { darkMode, hideClicked, setHideClicked } = themeContext;
  const location = useLocation();
  const [qiitaCount, setQiitaCount] = useState(0);
  const [zennCount, setZennCount] = useState(0);
  const { clickCount } = useClickContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let qiita_json = await JSON.parse(await (await fetch('/get_qiita')).text());
        let zenn_json = await JSON.parse(await (await fetch('/get_zenn')).text());
        setQiitaCount(qiita_json.array.length);
        setZennCount(zenn_json.array.length);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [hideClicked]);

  const headerStyleWithDarkMode = darkMode
    ? { ...headerStyle, backgroundColor: 'black', color: 'white' }
    : headerStyle;

  const handleDeleteClick = () => {
    const confirmDelete = window.confirm('クリックした記事の履歴を削除しますか？');
    if (confirmDelete) {
      localStorage.removeItem('clickHistory');
      window.location.reload();
    }
  };

  return (
    <div style={headerStyleWithDarkMode}>
      <h1>Tech Curation</h1>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link
          to="/"
          style={location.pathname === '/' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
        >
          List
        </Link>
        <Link
          to="/tagtrend"
          style={location.pathname === '/tagtrend' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
        >
          Tag Trend
        </Link>
        <span style={countStyle}>
          Qiita: {qiitaCount} / Zenn: {zennCount} (Clicked: {clickCount})
        </span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {hideClicked ?
          <FaEyeSlash onClick={() => setHideClicked(!hideClicked)} style={{ marginRight: '10px' }} />
          :
          <FaEye onClick={() => setHideClicked(!hideClicked)} style={{ marginRight: '10px' }} />
        }
        <FaTrash onClick={handleDeleteClick} style={{ cursor: 'pointer', marginRight: '10px' }} />
        <DarkModeSwitch />
      </div>
    </div>
  );
};

export default Header;
