// src/context/ClickContext.tsx
import React, { createContext, useState, useContext } from 'react';

interface ClickContextType {
  clickCount: number;
  updateClickCount: () => void;
}

const ClickContext = createContext<ClickContextType>({
  clickCount: 0,
  updateClickCount: () => {},
});

export const ClickProvider = ({ children }: { children: React.ReactNode }) => {
  const [clickCount, setClickCount] = useState(0);

  const updateClickCount = () => {
    const clickHistory = JSON.parse(localStorage.getItem('clickHistory') || '{}');
    setClickCount(Object.keys(clickHistory).length);
  };

  return (
    <ClickContext.Provider value={{ clickCount, updateClickCount }}>
      {children}
    </ClickContext.Provider>
  );
};

export const useClickContext = () => useContext(ClickContext);