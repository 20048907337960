import React, { useContext, CSSProperties } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CardList from './CardList';
import Header from './Header';
import TagTrend from './TagTrend';
import { ThemeContext, ThemeProvider } from '../context/ThemeContext';
import { ClickProvider } from '../context/ClickContext';

const lightModeStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column'
};

const darkModeStyle: CSSProperties = {
  ...lightModeStyle,
  backgroundColor: 'black',
  color: 'white',
};

const App = () => {
  const themeContext = useContext(ThemeContext);
  const { darkMode } = themeContext;

  const appStyleWithDarkMode = darkMode ? darkModeStyle : lightModeStyle;

  return (
    <ThemeProvider>
      <ClickProvider>
        <div style={appStyleWithDarkMode}>
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<CardList />} />
              <Route path="/tagtrend" element={<TagTrend />} />
            </Routes>
          </Router>
        </div>
      </ClickProvider>
    </ThemeProvider>
  );
};

export default App;
